import { COMPANY_LISTING_SUCCESS } from '../actionTypes';

export const initialState = {
	companies: []
};

const defaultAction = { type: '', payload: [] };

function companyListing(state = initialState, action = defaultAction) {
	switch (action.type) {
		case COMPANY_LISTING_SUCCESS: {
			const uniq: any = {};
			const removeDuplicates = Array.from(
				new Set([...state.companies, ...action.payload])
			);
			const arrFiltered = removeDuplicates.filter(
				(obj: any) => !uniq[obj.cursor] && (uniq[obj.cursor] = true)
			);
			return Object.assign(
				{},
				state,
				state.companies.length > 0
					? { companies: arrFiltered }
					: { companies: action.payload }
			);
		}
		default:
			return state;
	}
}

export default companyListing;
