import { SET_MAP_FILTER } from "../actionTypes";

export const initialState = {
  groupsFilter: "all"
}; // export for easy testing

const defaultAction = { type: "", payload: "" };

export default (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case SET_MAP_FILTER:
      return Object.assign({}, state, { groupsFilter: action.payload });
    default:
      return state;
  }
};
