import { SHOW_HIDE_SIDEBAR, ACTIVE_SIDEBAR_ITEM } from './../actionTypes';

export default function sidebarReducer(
	state = {
		open: false,
		activeItem: 'map'
	},
	action = {
		type: '',
		payload: ''
	}
) {
	switch (action.type) {
		case ACTIVE_SIDEBAR_ITEM:
			return Object.assign({}, state, { activeItem: action.payload });
		case SHOW_HIDE_SIDEBAR:
			return Object.assign({}, state, { open: !state.open });
		default:
			return state;
	}
}
