import {
  SET_SINGLE_USER_CONSUMPTION_FILTER,
  SET_SINGLE_USER_GENERATION_FILTER,
  SET_SINGLE_USER_STATISTICS_FILTER,
  SET_SINGLE_USER_STATISTICS_GROUP,
  SET_SINGLE_USER_METERS,
  SET_TWEETER_LIST_FILTER,
  SET_FILTER_COMPANY_EIKS,
  SET_COMPANIES_CONSUMPTION,
} from '../actionTypes';

export const initialState = {
  consumptionMeterIds: [],
  consumptionTimeFilterPeriod: 'DAY',
  consumptionTimeFilterFrom: '2018-05-11T15:04:05Z',
  consumptionTimeFilterTo: '2018-07-17T15:04:05Z',
  generationTimeFilter: 'week',
  statisticsTimeFilterPeriod: 'week',
  statisticsTimeFilterFrom: '2019-05-10T15:04:05Z',
  statisticsTimeFilterTo: '2019-05-17T15:04:05Z',
  statisticsGroup: 'bills',
  tweetsTimeFilterTo: new Date(Date.now()).toISOString(),
  tweetsTimeFilterFrom: new Date(
    new Date().setDate(new Date().getDate() - 7)
  ).toISOString(),
  companyEiks: [],
  consumptionCompaniesIds: [{eik: "121699202", label: "ЛУКОЙЛ БЪЛГАРИЯ ЕООД", value: "121699202"}]
}; // export for easy testing

const defaultAction = {
  type: '',
  payload: { from: '', to: '', period: '', group: '', ids: [], eiks: [] }
};

export default (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case SET_SINGLE_USER_METERS:
      return Object.assign({}, state, {
        consumptionMeterIds: action.payload.ids,
      });
    case SET_SINGLE_USER_CONSUMPTION_FILTER:
      return Object.assign({}, state, {
        consumptionTimeFilterFrom: action.payload.from,
        consumptionTimeFilterTo: action.payload.to,
        consumptionTimeFilterPeriod: action.payload.period,
      });
    case SET_SINGLE_USER_GENERATION_FILTER:
      return Object.assign({}, state, {
        generationTimeFilter: action.payload
      });
    case SET_SINGLE_USER_STATISTICS_FILTER:
      return Object.assign({}, state, {
        statisticsTimeFilterPeriod: action.payload.period,
        statisticsTimeFilterFrom: action.payload.from,
        statisticsTimeFilterTo: action.payload.to
      });
    case SET_SINGLE_USER_STATISTICS_GROUP:
      return Object.assign({}, state, {
        statisticsGroup: action.payload.group
      });
    case SET_TWEETER_LIST_FILTER:
      return Object.assign({}, state, {
        tweetsTimeFilterFrom: action.payload.from,
        tweetsTimeFilterTo: action.payload.to,
      });
    case SET_FILTER_COMPANY_EIKS:
      return Object.assign({}, state, {
        companyEiks: action.payload
      });
    case SET_COMPANIES_CONSUMPTION:
      return Object.assign({}, state, {
        consumptionCompaniesIds: action.payload.ids,
      });
    default:
      return state;
  }
};
