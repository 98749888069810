import {
  SET_FINANCIAL_ENERGY_MARKET_TIME_FILTER,
  SET_BREAKDOWN_ENERGY_CONSUMPTION_TIME_FILTER,
  SET_BREAKDOWN_ENERGY_CONSUMPTION_COUNTRY_FILTER,
  SET_FINANCIAL_COUNTRY_EXCHANGE_TIME_FILTER,
  SET_FINANCIAL_ENERGY_MARKET_COUNTRY_FILTER,
  SET_FINANCIAL_ENERGY_MARKET_SELECT_COUNTRY,
} from '../actionTypes';

export const initialState = {
  energyMarketTimeFilterFrom: '2018-09-10T15:04:05Z',
  energyMarketTimeFilterTo: '2018-10-17T15:04:05Z',
  energyMarketTimeFilterPeriod: 'day',
  breakdownEnergyConsumptionTimeFilterFrom: '2019-05-10T15:04:05Z',
  breakdownEnergyConsumptionTimeFilterTo: '2019-05-17T15:04:05Z',
  countryExchangeTimeFilterFrom: '2019-05-27T15:04:05Z',
  countryExchangeTimeFilterTo: '2019-05-30T15:04:05Z',
  selectCountryExchange: 'bg',
  energyMarketTimeCountry: [
    { key: 'bg', title: 'Bulgaria', checked: true },
    { key: 'fr', title: 'France', checked: true },
    { key: 'at', title: 'Austria', checked: true, },
    { key: 'swiss', title: 'Switzerland', checked: true },
    { key: 'de', title: 'Germany', checked: true },
    { key: 'ro', title: 'Romania', checked: true, },
    { key: 'hu', title: 'Hungary', checked: true },
  ],
  energyBreakdownCountry: [
    { key:"dk-dk1", title: "West Denmark", checked: false},
    { key:"pt", title: "Portugal", checked: false},
    { key:"it-sic", title: "Sicily Italy", checked: false},
    { key:"gb-ork", title: "Orkney Islands", checked: false},
    { key:"me", title: "Montenegro", checked: false},
    { key:"no-no3", title: "Middle Norway", checked: false},
    { key:"it-sar", title: "Sardinia Italy", checked: false},
    { key:"it-so", title: "South Italy", checked: false},
    { key:"it-cso", title: "Central South Italy", checked: false},
    { key:"cy", title: "Cyprus", checked: false},
    { key:"de", title: "Germany", checked: true},
    { key:"nl", title: "Netherlands", checked: false},
    { key:"dk-dk2", title: "East Denmark", checked: false},
    { key:"es", title: "Spain", checked: false},
    { key:"hu", title: "Hungary", checked: true},
    { key:"ro", title: "Romania", checked: true},
    { key:"mk", title: "Republic of Macedonia", checked: false},
    { key:"sk", title: "Slovakia", checked: false},
    { key:"ie", title: "Ireland", checked: false},
    { key:"bg", title: "Bulgaria", checked: true},
    { key:"no-no4", title: "North Norway", checked: false},
    { key:"cz", title: "Czechia", checked: false},
    { key:"pl", title: "Poland", checked: false},
    { key:"dk-bhm", title: "Bornholm", checked: false},
    { key:"ee", title: "Estonia", checked: false},
    { key:"it-no", title: "North Italy", checked: false},
    { key:"ua", title: "Ukraine", checked: false},
    { key:"lv", title: "Latvia", checked: true},
    { key:"gb-nir", title: "Northern Ireland", checked: false},
    { key:"fi", title: "Finland", checked: false},
    { key:"no-no2", title: "Southwest Norway", checked: false},
    { key:"ba", title: "Bosnia and Herzegovina", checked: false},
    { key:"be", title: "Belgium", checked: false},
    { key:"lt", title: "Lithuania", checked: false},
    { key:"gr", title: "Greece", checked: false},
    { key:"rs", title: "Serbia", checked: false},
    { key:"no-no1", title: "Southeast Norway", checked: false},
    { key:"si", title: "Slovenia", checked: false},
    { key:"at", title: "Austria", checked: true},
    { key:"md", title: "Republic of Moldova", checked: false},
    { key:"it-cno", title: "Central North Italy", checked: false},
    { key:"dk", title: "Denmark", checked: false},
    { key:"no-no5", title: "West Norway", checked: false},
  ],
}; // export for easy testing

const defaultAction = {
  type: '',
  payload: { from: '', to: '', period: '' }
};

export default (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case SET_FINANCIAL_ENERGY_MARKET_TIME_FILTER:
      return Object.assign({}, state, {
        ...state,
        energyMarketTimeFilterFrom: action.payload.from,
        energyMarketTimeFilterTo: action.payload.to,
        energyMarketTimeFilterPeriod: action.payload.period,
      });
    case SET_BREAKDOWN_ENERGY_CONSUMPTION_TIME_FILTER:
      return Object.assign({}, state, {
        ...state,
        breakdownEnergyConsumptionTimeFilterFrom: action.payload.from,
        breakdownEnergyConsumptionTimeFilterTo: action.payload.to
      });
    case SET_FINANCIAL_COUNTRY_EXCHANGE_TIME_FILTER:
      return Object.assign({}, state, {
        ...state,
        countryExchangeTimeFilterFrom: action.payload.from,
        countryExchangeTimeFilterTo: action.payload.to,
      });
    case SET_FINANCIAL_ENERGY_MARKET_COUNTRY_FILTER:
      return Object.assign({}, state, {
        ...state,
        energyMarketTimeCountry: action.payload,
      });
    case SET_FINANCIAL_ENERGY_MARKET_SELECT_COUNTRY:
      return Object.assign({}, state, {
        ...state,
        selectCountryExchange: action.payload,
      });
    case SET_BREAKDOWN_ENERGY_CONSUMPTION_COUNTRY_FILTER:
      return Object.assign({}, state, {
        ...state,
        energyBreakdownCountry: action.payload,
      });
    default:
      return state;
  }
};
