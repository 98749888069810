// @ts-ignore
import React, { Suspense, lazy } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';

// MATERIAL UI COMPONENTS
import CircularProgress from '@material-ui/core/CircularProgress';

//REDUX
import { connect } from 'react-redux';

// GRAPHQL COMPONENTS
import { Query } from 'react-apollo';
import { CURRENT_USER } from '../../graphql/queries';

//TYPES AND INTERFACES

//OTHER
import { UserContext } from '../../utills/context';
import { QueryResult } from '../../utills/types';

export const SingleClientDashboard = withRouter(
  lazy(() => import('../../views/SingleClientView'))
);

export const AdminUserDashboard = withRouter(
  lazy(() => import('../../views/AdminUserDashboard'))
);
export const Reports = withRouter(
  lazy(() => import('../../views/ReportsView'))
);
export const Map = withRouter(lazy(() => import('../../views/MapView')));
export const Processing = withRouter(
  lazy(() => import('../../views/ProcessingView'))
);
export const Finance = withRouter(
  lazy(() => import('../../views/FinancialView'))
);
export const Companies = withRouter(
  lazy(() => import('../../views/CompaniesView'))
);
export const Calendar = withRouter(
  lazy(() => import('../../views/CalendarView'))
);
export const Weather = withRouter(
  lazy(() => import('../../views/WeatherView'))
);

const loadCurrentlyActiveComponent = (activeItem: string) => {
  switch (activeItem) {
    case 'map':
      return Map;
    case 'dashboard':
      return AdminUserDashboard;
    case 'processing':
      return Processing;
    case 'finance':
      return Finance;
    case 'clients':
      return Companies;
    case 'calendar':
      return Calendar;
    case 'reports':
      return Reports;
    // case 'weather':
    //   return Weather;
    default:
      return AdminUserDashboard;
  }
};

// For now we have 4 basic roles: Admin, Analyst, Esco and Regular
const permittedRoutesBasedOnRole = (role: string, activeItem: string) => {
  switch (role) {
    case 'ADMIN':
      return [
        <Route key={0} path='/dashboard' component={AdminUserDashboard} />,
        <Route key={1} path='/map' component={Map} />,
        <Route key={2} path='/processing' component={Processing} />,
        <Route key={4} path='/finance' component={Finance} />,
        <Route key={5} path='/clients' component={Companies} />,
        <Route key={6} path='/client/:id' component={SingleClientDashboard} />,
        <Route key={7} path='/weather' component={Weather} />,
        <Route key={8} path='/calendar' component={Calendar} />,
        <Route key={9} path='/reports' component={Reports} />,

        <Route
          key={9}
          path='/'
          component={loadCurrentlyActiveComponent(activeItem)}
        />
      ];
    default:
      return [<Route
        key={8}
        path='/'
        component={loadCurrentlyActiveComponent(activeItem)}
      />];
  }
};

interface Props {
  path?: any;
  activeItem: string;
}

export default connect(({ sidebar }: any) => ({
  activeItem: sidebar.activeItem
}))(({ activeItem }: Props) => (
  <Query
    query={CURRENT_USER}
    fetchPolicy={'network-only'}
  >
    {({ loading, error, data }: QueryResult) => {
      return loading ? (
        <CircularProgress id='circular-progress-loading' size={70} />
      ) : error ? (
        <Redirect to='/login' />
      ) : (
            <UserContext.Provider value={data.currentUser}>
              <Suspense
                fallback={
                  <CircularProgress id='circular-progress-loading' size={70} />
                }
              >
                <Switch>
                  {permittedRoutesBasedOnRole(data.currentUser.role, activeItem)}
                </Switch>
              </Suspense>
            </UserContext.Provider>
          );
    }}
  </Query>
));
