export const migrations = {
	0: (state: any) => {
		return {
			...state
		};
	},
	1: (state: any) => {
		return {
			...state,
			financialFilters: {
				energyMarketTimeFilterFrom: '2018-08-10T15:04:05Z',
				energyMarketTimeFilterTo: '2018-11-17T15:04:05Z',
        energyMarketTimeFilterPeriod: 'week',
			}
		};
	},
	2: (state: any) => {
		return {
			...state,
			financialFilters: {
				energyMarketTimeFilterFrom: '2018-09-10T15:04:05Z',
				energyMarketTimeFilterTo: '2018-10-17T15:04:05Z',
        energyMarketTimeFilterPeriod: 'day',
			}
		};
  },
  3: (state: any) => {
		return {
			...state,
			financialFilters: {
        energyMarketTimeFilterFrom: '2018-09-10T15:04:05Z',
        energyMarketTimeFilterTo: '2018-10-17T15:04:05Z',
        energyMarketTimeFilterPeriod: 'day',
        breakdownEnergyConsumptionTimeFilterFrom: '2019-05-10T15:04:05Z',
        breakdownEnergyConsumptionTimeFilterTo: '2019-05-17T15:04:05Z',
        countryExchangeTimeFilterFrom: '2019-05-27T15:04:05Z',
        countryExchangeTimeFilterTo: '2019-05-30T15:04:05Z',
        selectCountryExchange: 'bg',
      },
      userConsumption: {
        consumptionMeterIds: [],
        consumptionTimeFilterPeriod: 'DAY',
        consumptionTimeFilterFrom: '2018-05-11T15:04:05Z',
        consumptionTimeFilterTo: '2018-07-17T15:04:05Z',
        generationTimeFilter: 'week',
        statisticsTimeFilterPeriod: 'week',
        statisticsTimeFilterFrom: '2019-05-10T15:04:05Z',
        statisticsTimeFilterTo: '2019-05-17T15:04:05Z',
        statisticsGroup: 'bills',
        consumptionCompaniesIds: []
      }
		};
  },
  4: (state: any) => {
    return {
      ...state,
      processing: {
        satisticsTimeFilterPeriod: 'day',
        satisticsTimeFilterFrom: '2018-07-10T15:04:05Z',
        satisticsTimeFilterTo: '2018-08-17T15:04:05Z',
        statisticsGroup: 'consumption'
      }
    }
  },
  5: (state: any) => {
    return {
      ...state,
      financialFilters: {
        energyMarketTimeFilterFrom: '2018-09-10T15:04:05Z',
        energyMarketTimeFilterTo: '2018-10-17T15:04:05Z',
        energyMarketTimeFilterPeriod: 'day',
        breakdownEnergyConsumptionTimeFilterFrom: '2019-05-10T15:04:05Z',
        breakdownEnergyConsumptionTimeFilterTo: '2019-05-17T15:04:05Z',
        countryExchangeTimeFilterFrom: '2019-05-27T15:04:05Z',
        countryExchangeTimeFilterTo: '2019-05-30T15:04:05Z',
        selectCountryExchange: 'bg',
        energyMarketTimeCountry: [
          { key: 'bg', title: 'Bulgaria', checked: true },
          { key: 'fr', title: 'France', checked: true },
          { key: 'at', title: 'Austria', checked: true, },
          { key: 'swiss', title: 'Switzerland', checked: true },
          { key: 'de', title: 'Germany', checked: true },
          { key: 'ro', title: 'Romania', checked: true, },
          { key: 'hu', title: 'Hungary', checked: true },
        ],
      }
    }
  },
  6: (state: any) => {
    return {
      ...state,
      processing: {
        satisticsTimeFilterPeriod: 'day',
        satisticsTimeFilterFrom: '2018-07-10T15:04:05Z',
        satisticsTimeFilterTo: '2018-08-17T15:04:05Z',
        statisticsGroup: 'consumption',
        activeWeatherFilterTo: new Date(Date.now()).toISOString(),
        activeWeatherFilterFrom: new Date(
          new Date().setDate(new Date().getDate() - 3)
        ).toISOString(),
        staticWeatherFilterFrom: new Date(
          new Date().setDate(new Date().getDate() - 3)
        ).toISOString(),
        staticWeatherFilterTo: new Date(Date.now()).toISOString(),
      }
    }
  },
  7: (state: any) => {
    return {
      ...state,
      processing: {
        satisticsTimeFilterPeriod: 'day',
        satisticsTimeFilterFrom: '2018-07-10T15:04:05Z',
        satisticsTimeFilterTo: '2018-08-17T15:04:05Z',
        statisticsGroup: 'consumption',
        activeWeatherFilterTo: new Date(Date.now()).toISOString(),
        activeWeatherFilterFrom: new Date(
          new Date().setDate(new Date().getDate() - 3)
        ).toISOString(),
        staticWeatherFilterFrom: new Date(
          new Date().setDate(new Date().getDate() - 3)
        ).toISOString(),
        staticWeatherFilterTo: new Date(Date.now()).toISOString(),
      }
    }
  },
  8: (state: any) => {
    return {
      ...state,
      financialFilters: {
        energyBreakdownCountry: [
          { key:"dk-dk1", title: "West Denmark", checked: false},
          { key:"pt", title: "Portugal", checked: false},
          { key:"it-sic", title: "Sicily Italy", checked: false},
          { key:"gb-ork", title: "Orkney Islands", checked: false},
          { key:"me", title: "Montenegro", checked: false},
          { key:"no-no3", title: "Middle Norway", checked: false},
          { key:"it-sar", title: "Sardinia Italy", checked: false},
          { key:"it-so", title: "South Italy", checked: false},
          { key:"it-cso", title: "Central South Italy", checked: false},
          { key:"cy", title: "Cyprus", checked: false},
          { key:"de", title: "Germany", checked: true},
          { key:"nl", title: "Netherlands", checked: false},
          { key:"dk-dk2", title: "East Denmark", checked: false},
          { key:"es", title: "Spain", checked: false},
          { key:"hu", title: "Hungary", checked: true},
          { key:"ro", title: "Romania", checked: true},
          { key:"mk", title: "Republic of Macedonia", checked: false},
          { key:"sk", title: "Slovakia", checked: false},
          { key:"ie", title: "Ireland", checked: false},
          { key:"bg", title: "Bulgaria", checked: true},
          { key:"no-no4", title: "North Norway", checked: false},
          { key:"cz", title: "Czechia", checked: false},
          { key:"pl", title: "Poland", checked: false},
          { key:"dk-bhm", title: "Bornholm", checked: false},
          { key:"ee", title: "Estonia", checked: false},
          { key:"it-no", title: "North Italy", checked: false},
          { key:"ua", title: "Ukraine", checked: false},
          { key:"lv", title: "Latvia", checked: true},
          { key:"gb-nir", title: "Northern Ireland", checked: false},
          { key:"fi", title: "Finland", checked: false},
          { key:"no-no2", title: "Southwest Norway", checked: false},
          { key:"ba", title: "Bosnia and Herzegovina", checked: false},
          { key:"be", title: "Belgium", checked: false},
          { key:"lt", title: "Lithuania", checked: false},
          { key:"gr", title: "Greece", checked: false},
          { key:"rs", title: "Serbia", checked: false},
          { key:"no-no1", title: "Southeast Norway", checked: false},
          { key:"si", title: "Slovenia", checked: false},
          { key:"at", title: "Austria", checked: true},
          { key:"md", title: "Republic of Moldova", checked: false},
          { key:"it-cno", title: "Central North Italy", checked: false},
          { key:"dk", title: "Denmark", checked: false},
          { key:"no-no5", title: "West Norway", checked: false},
        ],
      }
    }
  },
  9: (state: any) => {
    return {
      ...state,
      financialFilters: {
        energyMarketTimeFilterFrom: '2018-09-10T15:04:05Z',
        energyMarketTimeFilterTo: '2018-10-17T15:04:05Z',
        energyMarketTimeFilterPeriod: 'day',
        breakdownEnergyConsumptionTimeFilterFrom: '2019-05-10T15:04:05Z',
        breakdownEnergyConsumptionTimeFilterTo: '2019-05-17T15:04:05Z',
        countryExchangeTimeFilterFrom: '2019-05-27T15:04:05Z',
        countryExchangeTimeFilterTo: '2019-05-30T15:04:05Z',
        selectCountryExchange: 'bg',
        energyMarketTimeCountry: [
          { key: 'bg', title: 'Bulgaria', checked: true },
          { key: 'fr', title: 'France', checked: true },
          { key: 'at', title: 'Austria', checked: true, },
          { key: 'swiss', title: 'Switzerland', checked: true },
          { key: 'de', title: 'Germany', checked: true },
          { key: 'ro', title: 'Romania', checked: true, },
          { key: 'hu', title: 'Hungary', checked: true },
        ],
        energyBreakdownCountry: [
          { key:"dk-dk1", title: "West Denmark", checked: false},
          { key:"pt", title: "Portugal", checked: false},
          { key:"it-sic", title: "Sicily Italy", checked: false},
          { key:"gb-ork", title: "Orkney Islands", checked: false},
          { key:"me", title: "Montenegro", checked: false},
          { key:"no-no3", title: "Middle Norway", checked: false},
          { key:"it-sar", title: "Sardinia Italy", checked: false},
          { key:"it-so", title: "South Italy", checked: false},
          { key:"it-cso", title: "Central South Italy", checked: false},
          { key:"cy", title: "Cyprus", checked: false},
          { key:"de", title: "Germany", checked: true},
          { key:"nl", title: "Netherlands", checked: false},
          { key:"dk-dk2", title: "East Denmark", checked: false},
          { key:"es", title: "Spain", checked: false},
          { key:"hu", title: "Hungary", checked: true},
          { key:"ro", title: "Romania", checked: true},
          { key:"mk", title: "Republic of Macedonia", checked: false},
          { key:"sk", title: "Slovakia", checked: false},
          { key:"ie", title: "Ireland", checked: false},
          { key:"bg", title: "Bulgaria", checked: true},
          { key:"no-no4", title: "North Norway", checked: false},
          { key:"cz", title: "Czechia", checked: false},
          { key:"pl", title: "Poland", checked: false},
          { key:"dk-bhm", title: "Bornholm", checked: false},
          { key:"ee", title: "Estonia", checked: false},
          { key:"it-no", title: "North Italy", checked: false},
          { key:"ua", title: "Ukraine", checked: false},
          { key:"lv", title: "Latvia", checked: true},
          { key:"gb-nir", title: "Northern Ireland", checked: false},
          { key:"fi", title: "Finland", checked: false},
          { key:"no-no2", title: "Southwest Norway", checked: false},
          { key:"ba", title: "Bosnia and Herzegovina", checked: false},
          { key:"be", title: "Belgium", checked: false},
          { key:"lt", title: "Lithuania", checked: false},
          { key:"gr", title: "Greece", checked: false},
          { key:"rs", title: "Serbia", checked: false},
          { key:"no-no1", title: "Southeast Norway", checked: false},
          { key:"si", title: "Slovenia", checked: false},
          { key:"at", title: "Austria", checked: true},
          { key:"md", title: "Republic of Moldova", checked: false},
          { key:"it-cno", title: "Central North Italy", checked: false},
          { key:"dk", title: "Denmark", checked: false},
          { key:"no-no5", title: "West Norway", checked: false},
        ],
      }
    }
  },
  10: (state: any) => {
    return {
      ...state,
      userConsumption: {
        consumptionMeterIds: [],
        consumptionTimeFilterPeriod: 'DAY',
        consumptionTimeFilterFrom: '2018-05-11T15:04:05Z',
        consumptionTimeFilterTo: '2018-07-17T15:04:05Z',
        generationTimeFilter: 'week',
        statisticsTimeFilterPeriod: 'week',
        statisticsTimeFilterFrom: '2019-05-10T15:04:05Z',
        statisticsTimeFilterTo: '2019-05-17T15:04:05Z',
        statisticsGroup: 'bills',
        companyEiks: [],
        consumptionCompaniesIds: [{eik: "121699202", label: "ЛУКОЙЛ БЪЛГАРИЯ ЕООД", value: "121699202"}]
      }
    };
  },
  11: (state: any) => {
    return {
      ...state,
      userConsumption: {
        consumptionMeterIds: [],
        consumptionTimeFilterPeriod: 'DAY',
        consumptionTimeFilterFrom: '2018-05-11T15:04:05Z',
        consumptionTimeFilterTo: '2018-07-17T15:04:05Z',
        generationTimeFilter: 'week',
        statisticsTimeFilterPeriod: 'week',
        statisticsTimeFilterFrom: '2019-05-10T15:04:05Z',
        statisticsTimeFilterTo: '2019-05-17T15:04:05Z',
        statisticsGroup: 'bills',
        companyEiks: [],
        tweetsTimeFilterTo: new Date(Date.now()).toISOString(),
        tweetsTimeFilterFrom: new Date(
          new Date().setDate(new Date().getDate() - 7)
        ).toISOString(),
        consumptionCompaniesIds: [{eik: "121699202", label: "ЛУКОЙЛ БЪЛГАРИЯ ЕООД", value: "121699202"}]
      }
    };
  },
  12: (state: any) => {
    return {
      ...state,
      processing: {
        satisticsTimeFilterPeriod: 'day',
        satisticsTimeFilterFrom: '2018-07-10T15:04:05Z',
        satisticsTimeFilterTo: '2018-08-17T15:04:05Z',
        statisticsGroup: 'consumption',
        activeWeatherFilterTo: new Date(Date.now()).toISOString(),
        activeWeatherFilterFrom: new Date(
          new Date().setDate(new Date().getDate() - 3)
        ).toISOString(),
        staticWeatherFilterFrom: new Date(
          new Date().setDate(new Date().getDate() - 3)
        ).toISOString(),
        staticWeatherFilterTo: new Date(Date.now()).toISOString(),
        generationForecastPeriod: 'DAY',
        generationForecastFrom: new Date(
          new Date().setDate(new Date().getDate() - 3)
        ).toISOString(),
        generationForecastTo: new Date(Date.now()).toISOString(),
        generationForecastCountries: ['bg', 'ro'],
      }
    };
  }
}