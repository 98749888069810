import { combineReducers } from 'redux';
import sidebar from './sidebar';
import processing from './processingStatisticsFilters';
import mapFilter from './mapFilter';
import userConsumption from './userConsumption';
import financialFilters from './financialFilters';
import companyListing from './companyListing';
export default combineReducers({
  sidebar,
  processing,
  userConsumption,
  mapFilter,
  financialFilters,
  companyListing
});
