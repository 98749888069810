import gql from 'graphql-tag'; // tslint:disable-line
export const CURRENT_USER = gql`
  query {
    currentUser {
      id
      email
      name
      role
      companies {
        id
        name
      }
    }
  }
`;

export const COMPANYLISTING = gql`
  query CompanyListing($first: Int, $after: String, $search: String) {
    companyListing(first: $first, after: $after, search: $search) {
      totalCount
      edges {
        node {
          id
          address
          name
          eik
          clientNumber
          contracts {
            contract
            users {
              id
              name
              email
              role
            }
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GETCOMPANIES = gql`
  query GetCompanies($search: String) {
    getCompanies(search: $search) {
      id
      address
      name
      eik
      clientNumber
      contracts {
        contract
        users {
          id
          name
          email
          role
        }
      }
    }
  }
`;

export const GETCOMPANY = gql`
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      eik
      mol
      address
      balance
      balancedAt
      clientNumber
      contracts {
        contract
        users {
          id
          name
          email
          role
        }
      }
    }
  }
`;

export const MARKET_PRICES = gql`
  query GetMarketPrices(
    $countries: [String!]!
    $from: Time!
    $to: Time!
    $period: Period!
  ) {
    getMarketPrices(
      countries: $countries
      from: $from
      to: $to
      period: $period
    ) {
      date
      countries {
        name
        consumption
        price
      }
    }
  }
`;

export const WEATHER_FORECAST = gql`
  query WeatherForecast(
    $city: String!
    $from: Time!
    $to: Time!
    $period: Period!
  ) {
    weatherForecast(city: $city, from: $from, to: $to, period: $period) {
      date
      temperature
      type
      solarRadiation
      windSpeed
    }
  }
  `;

export const SINGLE_ENERGY_CONSUMPTION = gql`
query GetEnergyConsumption($companyID: ID!, $from: Time!, $to: Time!, $period: Period!) {
  getEnergyConsumption(companyID: $companyID, from: $from, to: $to, period: $period) {
    average 
    total 
    previousTotal
    values {
      time
      meters {
        name
        value
      }
    }
  }
}
`;

export const PROCESSED_FILES = gql`
  query GetPreprocessedFiles($fType: PreprocessedFileType!) {
    getPreprocessedFiles(fType: $fType) {
      name
      lastModified
    }
  }
`;

export const POWERBREAKDOWN_CONSUMPTION = gql`
  query GetBreakdownConsumption(
    $countries: [String!]!
    $from: Time!
    $to: Time!
    $period: Period!
  ) {
    getPowerBreakdownConsumption(
      countries: $countries
      from: $from
      to: $to
      period: $period
    ) {
      Time
      Countries{
        Country
        Biomass
        Coal
        Gas
        Geothermal
        Hydro
        Nuclear
        Oil
        Solar
        Wind
        Unknown
      }
    }
  }
`;

export const INVOICELISTING = gql`
  query InvoiceListing(
    $first: Int
    $after: String
    $search: String
    $companyIDs: [ID]!
    $seen: Boolean
  ) {
    invoiceListing(
      first: $first
      after: $after
      search: $search
      companyIDs: $companyIDs
      seen: $seen
    ) {
      totalCount
      edges {
        node {
          name
          bulstat
          number
          date
          amount
          pdf
          type
          status
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const CONSUMPTION_BY_GROUPS = gql`
  query GetEnergyConsumptionByGroup(
    $from: Time!
    $to: Time!
    $period: Period!
  ) {
    getEnergyConsumptionByGroup(from: $from, to: $to, period: $period) {
      time
      meters {
        name
        value
      }
    }
  }
`;

export const COUNTRY_EXCHANGE = gql`
  query GetCountryExchange(
    $country: String!
    $from: Time!
    $to: Time!
  ) {
    getCountryExchange(
      country: $country
      from: $from
      to: $to
    ) {
      country
      import 
      export
    }
  }
`;

export const TWEET_LISTING = gql`
  query tweetListing(
    $first: Int
    $after: String
    $from: Time!
    $to: Time!
  ) {
    tweetListing(
      first: $first
      after: $after
      from: $from
      to: $to
    ) {
      totalCount
      edges {
        node {
          id
          user_id
          text
          date
          url
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_EVENTS = gql`
  query getEvents(
    $startDate: Time!
    $endDate: Time!
  ) {
    getEvents(
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      title
      protocol
      start
      end
      client
      site
      car
      kilometrage
      notes
    }
  }
`;

export const GET_CONSUMER_EARNINGS = gql`
  query GetConsumerEarnings(
    $companyID: ID!
    $from: Time!
    $to: Time!
  ) {
    getConsumerEarnings(
      companyID: $companyID
      from: $from
      to: $to
    ) {
      month
      paid
      unpaid
    }
  }
`;

export const VOLATILITY_INDEX = gql`
  query GetVolatilityIndex(
    $eiks: [String!]!
  ) {
    getVolatilityIndex(
      eiks: $eiks
    ) {
      index
      salesman
      eik
    }
  }
`;

export const SHORTCOMPANYLISTING = gql`
  query CompanyListing($first: Int, $after: String, $search: String) {
    companyListing(first: $first, after: $after, search: $search) {
      totalCount
      edges {
        node {
          name
          eik
        }
      }
    }
  }
`;

export const GENERATION_FORECAST = gql`
  query GenerationForecast($countries: [String!]!, $from: Time!, $to: Time!, $period: Period!) {
    getGenerationForecast(countries: $countries, from: $from, to: $to, period: $period) {
      date
      countries {
        wind
        solar
        name
      }

    }
  }
`;

export const MULTI_ENERGY_CONSUMPTION = gql`
  query MultipleConsumption($eiks: [String!]!, $from: Time!, $to: Time!, $period: Period!) {
    getMultipleConsumption(eiks: $eiks, from: $from, to: $to, period: $period) {
      date
      companies {
        eik
        consumption
      }
    }
  }
`;