// @ts-ignore
import React, { Suspense, lazy } from 'react';
import * as ReactDOM from 'react-dom';
import {
	BrowserRouter,
	Switch,
	Route,
	Redirect,
	withRouter
} from 'react-router-dom';

//REDUX
import {
	createStore,
	applyMiddleware
} from 'redux';
import { Provider } from 'react-redux';
import {
	createMigrate,
	persistStore,
	persistReducer
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import rootReducer from './store/reducers';
import thunk from 'redux-thunk';

//GRAPHQL APOLLO
import ApolloClient from 'apollo-client'; // tslint:disable-line
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { RetryLink } from 'apollo-link-retry';
import { ApolloProvider } from 'react-apollo';

// MATERIAL UI COMPONENTS
import CircularProgress from '@material-ui/core/CircularProgress';
import 'typeface-roboto';

// STYLES
import './index.css';

//  COMPONENTS
import AuthorizedRoute from './components/AuthorizedRoute';

//OTHER
//import * as Raven from "raven-js";
import { migrations } from './reduxMigrations';

let API_URL = 'https://staging-gvi.stageai.io/api';
// let API_URL = 'http://127.0.0.1:8080/query';


if (process.env.REACT_APP_BUILD_ENV === 'production') {
	// Raven.config(
	//   "https://a07bea9fb14b494897a703072248bb4c@sentry.yatrusanalytics.com/4"
	// ).install();
	API_URL = 'https://gvi.stageai.io/api';
}
const authLink = setContext(async (req, { headers }) => {
	const token = await localStorage.getItem('userToken');
	return {
		...headers,
		headers: { Authorization: token ? `Bearer ${token}` : null }
	};
});

const retry = new RetryLink({ attempts: { max: Infinity } });
const link = ApolloLink.from([
	retry,
	authLink,
	new HttpLink({
		uri: API_URL,
		fetchOptions: {
			credentials: 'include'
		}
	})
]);
const cache = new InMemoryCache({
	dataIdFromObject: (object) => object.id
});
const client = new ApolloClient({
	cache,
	link
});

const persistConfig = {
	key: 'root',
	storage,
	version: 12,
	migrate: createMigrate(migrations, { debug: true }),

};

const persistedReducer = persistReducer(persistConfig, rootReducer);
// Redux Devtools is a Chrome extention for better debugging and developer exprience

/* tslint:disable */
// const w: any = window as any;
// const composeEnhancers =
//   process.env.NODE_ENV !== "production" &&
//   typeof w !== "undefined" &&
//   w.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     ? w.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     : compose;
// /* tslint:enable */
const store = createStore(persistedReducer, applyMiddleware(thunk));

export const asyncLogin = withRouter(lazy(() => import('./views/LoginView')));
export const asyncForgottenPassword = withRouter(lazy(() =>
	import('./views/ForgottenPasswordView')
));
export const asyncRegister = withRouter(lazy(() => import('./views/RegisterView')));
// export const asyncFinancial = lazy(() => import("./views/FinancialView"));

ReactDOM.render(
	<ApolloProvider client={client}>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistStore(store)}>
				<BrowserRouter>
					<Suspense
						fallback={
							<CircularProgress id='circular-progress-loading' size={70} />
						}
					>
						<Switch>
							<Route path='/login' component={asyncLogin} />
							<Route
								path='/forgottenPassword'
								component={asyncForgottenPassword}
							/>
							<Route path='/register' component={asyncRegister} />
							<AuthorizedRoute path='/' />
							<Redirect to='/login' />
						</Switch>
					</Suspense>
				</BrowserRouter>
			</PersistGate>
		</Provider>
	</ApolloProvider>,
	document.getElementById('root') as HTMLElement
);
