import {
  SET_PROCESSING_STATISTICS_GRAPHIC_FILTER,
  SET_PROCESSING_STATISTICS_GRAPHIC_GROUP,
  SET_PROCESSING_WEATHER_FILTER,
  SET_GENERATION_FORECAST_FILTER,
  SET_GENERATION_FORECAST_COUNTRIES
} from '../actionTypes';

export const initialState = {
  activeWeatherFilterTo: new Date(Date.now()).toISOString(),
  activeWeatherFilterFrom: new Date(
    new Date().setDate(new Date().getDate() - 3)
  ).toISOString(),
  staticWeatherFilterFrom: new Date(
    new Date().setDate(new Date().getDate() - 3)
  ).toISOString(),
  staticWeatherFilterTo: new Date(Date.now()).toISOString(),
  satisticsTimeFilterPeriod: 'day',
  satisticsTimeFilterFrom: '2018-07-04T15:04:05Z',
  satisticsTimeFilterTo: '2018-08-17T15:04:05Z',
  statisticsGroup: 'consumption',
  generationForecastPeriod: 'DAY',
  generationForecastFrom: new Date(
    new Date().setDate(new Date().getDate() - 3)
  ).toISOString(),
  generationForecastTo: new Date(Date.now()).toISOString(),
  generationForecastCountries: ['bg', 'ro'],
}; // export for easy testing

const defaultAction = {
  type: '',
  payload: { from: '', to: '', period: '', group: '', countries: [] }
};

export default (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case SET_PROCESSING_STATISTICS_GRAPHIC_FILTER:
      return Object.assign({}, state, {
        satisticsTimeFilterPeriod: action.payload.period,
        satisticsTimeFilterFrom: action.payload.from,
        satisticsTimeFilterTo: action.payload.to
      });
    case SET_PROCESSING_STATISTICS_GRAPHIC_GROUP:
      return Object.assign({}, state, {
        statisticsGroup: action.payload.group
      });
    case SET_PROCESSING_WEATHER_FILTER: 
      return Object.assign({}, state, {
        activeWeatherFilterFrom: action.payload.from,
        activeWeatherFilterTo: action.payload.to
      });
    case SET_GENERATION_FORECAST_FILTER: 
      return Object.assign({}, state, {
        generationForecastFrom: action.payload.from,
        generationForecastTo: action.payload.to,
      });
    case SET_GENERATION_FORECAST_COUNTRIES:
      return Object.assign({}, state, {
        generationForecastCountries: action.payload,
      });
    default:
      return state;
  }
};
