export const SHOW_HIDE_SIDEBAR = 'SIDEBAR/TOGGLE';
export const ACTIVE_SIDEBAR_ITEM = 'SIDEBAR/ACTIVEITEM/CHANGE';

export const SET_PROCESSING_STATISTICS_GRAPHIC_FILTER =
  'PROCESSING/STATISTICS/FILTER/CHANGE';
export const SET_PROCESSING_STATISTICS_GRAPHIC_GROUP =
  'PROCESSING/STATISTICS/GROUP/CHANGE';
export const SET_MAP_FILTER = 'MAP/FILTER/CHANGE';
export const SET_SINGLE_USER_CONSUMPTION_FILTER =
  'USER/CONSUMPTION/FILTER/CHANGE';
export const SET_SINGLE_USER_METERS = 'USER/CONSUMPTION/FILTER/METERS';
export const SET_SINGLE_USER_STATISTICS_FILTER =
  'USER/STATISTICS/BILLS/FILTER/CHANGE';
export const SET_SINGLE_USER_STATISTICS_GROUP = 'USER/STATISTICS/GROUP/CHANGE';
export const SET_SINGLE_USER_GENERATION_FILTER =
  'USER/GENERATION/FILTER/CHANGE';
export const SET_FINANCIAL_ENERGY_MARKET_TIME_FILTER =
  'FINANCIAL/ENERGY/MARKET/FILTER/CHANGE';
export const SET_BREAKDOWN_ENERGY_CONSUMPTION_TIME_FILTER =
  'BREAKDOWN/ENERGY/CONSUMPTION/FILTER/CHANGE';
export const SET_BREAKDOWN_ENERGY_CONSUMPTION_COUNTRY_FILTER = 
  'BREAKDOWN/ENERGY/COUNTRY/CHANGE';

export const COMPANY_LISTING_REQUEST = 'COMPANY/LISTING/RQUEST';
export const COMPANY_LISTING_SUCCESS = 'COMPANY/LISTING/SUCCESS';
export const COMPANY_LISTING_FAIL = 'COMPANY/LISTING/FAIL';

export const SET_FINANCIAL_COUNTRY_EXCHANGE_TIME_FILTER =
  'COUNTRY/EXCHANGE/FILTER/CHANGE';

export const SET_FINANCIAL_ENERGY_MARKET_COUNTRY_FILTER =
  'FINANCIAL/ENERGY/MARKET/COUNTRY/CHANGE';
export const SET_FINANCIAL_ENERGY_MARKET_SELECT_COUNTRY =
  'FINANCIAL/ENERGY/MARKET/FILTER/CHANGE/COUNTRY';
export const SET_PROCESSING_WEATHER_FILTER =
  'PROCESSING/STATISTICS/FILTER/WEATHER';
export const SET_TWEETER_LIST_FILTER = 'USER/TWEETER/FILTER';
export const SET_FILTER_COMPANY_EIKS =
  'USER/CONSUMPTION/FILTER/COMPANY';
export const SET_COMPANIES_CONSUMPTION =
  'USER/CONSUMPTION/COMPANIES';
export const SET_GENERATION_FORECAST_FILTER =
  'USER/WEATHER/GENERATION/FORECAST';
  export const SET_GENERATION_FORECAST_COUNTRIES =
  'USER/WEATHER/GENERATION/FORECAST/COUNTRIES';